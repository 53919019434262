var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('div',{staticClass:"d-inline-flex"},[_c('label',[_vm._v("Hoá đơn đỏ ")]),_c('b-checkbox',{staticClass:"ml-2",model:{value:(_vm.billData.companyBill),callback:function ($$v) {_vm.$set(_vm.billData, "companyBill", $$v)},expression:"billData.companyBill"}})],1),(_vm.billData.companyBill)?_c('b-form-radio-group',{staticClass:"mt-2",attrs:{"options":[
        { text: 'Công ty', value: 1 },
        { text: 'Cá nhân', value: 2 },
      ],"name":"radio-inline"},model:{value:(_vm.vatInvoice.vatCompanyType),callback:function ($$v) {_vm.$set(_vm.vatInvoice, "vatCompanyType", $$v)},expression:"vatInvoice.vatCompanyType"}}):_vm._e()],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.billData.companyBill),expression:"billData.companyBill"}],attrs:{"disabled":_vm.disabled}},[(_vm.vatInvoice.vatCompanyType === 2)?_c('label',{staticClass:"mt-2"},[_vm._v("Mã số cá nhân:")]):_c('label',{staticClass:"mt-2"},[_vm._v("Mã số thuế:")]),_c('b-form-input',{staticClass:"input-style",attrs:{"state":_vm.stateTaxCode,"size":"sm","trim":"","placeholder":_vm.vatInvoice.vatCompanyType === 2
          ? 'Nhập mã số cá nhân'
          : 'Nhập mã số thuế'},model:{value:(_vm.vatInvoice.taxCode),callback:function ($$v) {_vm.$set(_vm.vatInvoice, "taxCode", $$v)},expression:"vatInvoice.taxCode"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.stateTaxCode}},[_vm._v("Mã số thuế không hợp lệ")])],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.billData.companyBill),expression:"billData.companyBill"}],attrs:{"disabled":_vm.disabled}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.vatInvoice.vatCompanyType === 2)?_c('label',[_vm._v("Họ và tên:")]):_c('label',[_vm._v("Tên công ty:")]),_c('a',{staticClass:"mb-3",attrs:{"href":"http://tracuunnt.gdt.gov.vn/tcnnt/mstdn.jsp","target":"_blank"}},[_vm._v("Tra cứu Thuế "),_c('i',{staticClass:"flaticon2-search text-primary"})])]),_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(_vm.vatInvoice.companyName),expression:"vatInvoice.companyName",modifiers:{"hover":true,"bottom":true}}],staticClass:"input-style",attrs:{"size":"sm","type":"text","placeholder":_vm.vatInvoice.vatCompanyType === 2
          ? 'Nhập họ và tên'
          : 'Nhập tên công ty'},model:{value:(_vm.vatInvoice.companyName),callback:function ($$v) {_vm.$set(_vm.vatInvoice, "companyName", $$v)},expression:"vatInvoice.companyName"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.billData.companyBill),expression:"billData.companyBill"}],attrs:{"disabled":_vm.disabled}},[(_vm.vatInvoice.vatCompanyType === 2)?_c('label',[_vm._v("Địa chỉ:")]):_c('label',[_vm._v("Địa chỉ công ty:")]),_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(_vm.vatInvoice.companyAddress),expression:"vatInvoice.companyAddress",modifiers:{"hover":true,"bottom":true}}],staticClass:"input-style",attrs:{"size":"sm","type":"text","placeholder":_vm.vatInvoice.vatCompanyType === 2
          ? 'Nhập địa chỉ'
          : 'Nhập địa chỉ công ty'},model:{value:(_vm.vatInvoice.companyAddress),callback:function ($$v) {_vm.$set(_vm.vatInvoice, "companyAddress", $$v)},expression:"vatInvoice.companyAddress"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.billData.companyBill),expression:"billData.companyBill"}],attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Email:")]),_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(_vm.vatInvoice.email),expression:"vatInvoice.email",modifiers:{"hover":true,"bottom":true}}],staticClass:"input-style",attrs:{"state":_vm.stateEmail,"size":"sm","type":"email","placeholder":"Email, ngăn cách nhiều email bởi dấu ' ; '","trim":""},model:{value:(_vm.vatInvoice.email),callback:function ($$v) {_vm.$set(_vm.vatInvoice, "email", $$v)},expression:"vatInvoice.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.stateEmail}},[_vm._v("Email không hợp lệ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }